<template>
  <div class="screens">
    <div class="screens__wrapper">
      <div class="screens__header">
        <div class="screens__title">{{ $t("pages.screens.title") }}</div>
        <Button
          v-if="license"
          class="screens__add-btn"
          @click="showScreenModal = true"
          theme="secondary"
          icon="Plus"
        >
          {{ $t("pages.screens.addButton") }}
        </Button>
      </div>
      <div class="screens_content">
        <Screen
          v-for="(screen, index) in screens"
          :key="index"
          :index="index"
          :screen="screen"
          :tags="tags"
          class="screens__screen"
        ></Screen>
        <Logs class="screens_logs" v-if="issues"></Logs>
      </div>
    </div>

    <Modal
      class="screens__modal"
      @close="onClose"
      v-if="showScreenModal"
      :heading="$t('pages.screens.screenModal.title')"
    >
      <div class="screens__modal-content" slot="modal-content">
        <div class="screens__modal-available">
          {{ $t("pages.screens.screenModal.available") }}:
          {{ license.available }}
        </div>
        <form
          v-if="license.available > 0"
          class="screens__form"
          @submit.prevent="onCreateScreen"
        >
          <div class="screens__field">
            <label class="screens__label" for="ScreenTitle">{{
              $t("pages.screens.screenModal.label")
            }}</label>
            <input
              name="ScreenTitle"
              class="screens__input"
              v-model="newScreen.name"
              type="text"
            />
          </div>

          <div class="screens__loading-bar"></div>
          <Button class="screens__create-btn" type="submit">
            {{ $t("pages.screens.screenModal.createButton") }}
          </Button>
          <div class="screens__error" v-if="feedback">{{ feedback }}</div>
        </form>
        <Button
          class="screens__create-btn"
          @click="goToSupport"
          v-else
          theme="secondary"
        >
          {{ $t("pages.editor.creditModal.contactBtn") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Screen from "@/components/Screen";
import Button from "@/components/Button";
import Logs from "@/components/Logs";
import Modal from "@/components/Modal";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Screen,
    Button,
    Logs,
    Modal
  },
  data: () => ({
    newScreen: {},
    showScreenModal: false,
    issues: false,
    feedback: ""
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),
    ...mapGetters("screens", {
      license: "getScreenLicenses"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    hasParent() {
      return this.team.parentTeam ? true : false;
    },
    screens() {
      return this.team.screens ? this.team.screens : [];
    },
    pTags() {
      if (this.parentTeam && this.parentTeam.tags) {
        let keys = Object.keys(this.parentTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.parentTeam.tags[key].isParentTag = true;
          });

          return this.parentTeam.tags;
        }
      }

      return {};
    },
    rTags() {
      if (this.rootTeam && this.rootTeam.tags) {
        let keys = Object.keys(this.rootTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.rootTeam.tags[key].isRootTag = true;
          });

          return this.rootTeam.tags;
        }
      }

      return {};
    },
    tags() {
      let tags = {};
      if (Object.keys(this.pTags).length > 0) {
        tags = {
          ...tags,
          ...this.pTags
        };
      }

      if (
        Object.keys(this.rTags).length > 0 &&
        this.team.rootTeam !== this.team.parentTeam
      ) {
        tags = {
          ...tags,
          ...this.rTags
        };
      }

      let teamTags =
        this.team.tags && Object.keys(this.team.tags).length !== 0
          ? this.team.tags
          : {};

      return {
        ...tags,
        ...teamTags
      };
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchScreensLicenses(this.user.currentTeam);
      });
    } else if (storedTeam) {
      this.fetchScreensLicenses(storedTeam);
    } else {
      this.fetchScreensLicenses(this.user.currentTeam);
    }
  },
  methods: {
    ...mapActions("screens", [
      "createScreen",
      "fetchScreensLicenses",
      "updateScreensLicenses"
    ]),
    ...mapActions("notifications", ["addNotification"]),
    ...mapActions("user", ["fetchUserProfile"]),
    goToSupport() {
      this.$router.push({
        name: "Settings",
        params: {
          id: "support"
        }
      });
    },
    onCreateScreen() {
      if (this.newScreen.name) {
        this.createScreen({
          ...this.newScreen,
          online: true,
          orientation: "landscape",
          id: String(new Date().getTime())
        })
          .then(() => {
            this.addNotification({
              title: "screen",
              type: "success",
              autoRemove: true
            });

            this.updateScreensLicenses({
              licenses: Number(this.license.licenses),
              available: Number(this.license.available) - 1
            });
          })
          .catch(() => {
            this.addNotification({
              title: "error",
              type: "error",
              autoRemove: true
            });
          });
        this.onClose();
      } else {
        this.feedback = this.$t("pages.screens.screenModal.feedback");
      }
    },
    onClose() {
      this.newScreen = {};
      this.showScreenModal = false;
      this.feedback = "";
    }
  }
};
</script>

<style lang="scss">
.screens {
  min-height: 100vh;
  padding: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  &__modal-available {
    @apply text-sm;
  }

  &__add-btn {
    align-self: flex-end;
  }
  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }
  &__field {
    @apply mt-6;
  }
  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }
  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
  &__error {
    @apply text-ls-red mt-2;
  }
  &__label {
    margin-bottom: 0.25rem;
  }
  &__create-btn {
    @apply mt-4 w-full;
  }

  &__screen {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 900px) {
  .screens {
    padding: 36px 64px;
    display: flex;
    justify-content: center;

    &__wrapper {
      width: 745px;
    }
  }
}
</style>
