<template>
  <div
    class="tag"
    :class="{
      'tag--active': tag.chosen || active,
      'tag--parent': tag.isParentTag,
      'tag--root': tag.isRootTag
    }"
    v-if="tag"
  >
    <inline-svg
      class="tag__icon"
      :src="require(`../assets/icons/Tag_Screen_Portrait.svg`)"
      aria-label="Tag Icon"
      v-if="tag.icon === 'portrait'"
    ></inline-svg>
    <inline-svg
      class="tag__icon"
      :src="require(`../assets/icons/Tag_Screen_Landscape.svg`)"
      aria-label="Tag Icon"
      v-else-if="tag.icon === 'landscape'"
    ></inline-svg>
    <inline-svg
      class="tag__icon"
      :src="require(`../assets/icons/Tag.svg`)"
      aria-label="Tag Icon"
      v-else
    ></inline-svg>
    {{ tag.name }}
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.tag {
  @apply cursor-pointer border-ls-purple border text-ls-purple font-medium py-1 px-2 mr-2 rounded-full text-xs inline-flex items-center capitalize;

  &--active {
    @apply bg-ls-purple text-white;
  }

  &__icon {
    width: 12px;
    height: 12px;
    @apply mr-1;

    path,
    rect {
      @apply stroke-current;
    }
  }
}

.tag--active.tag--parent {
  @apply bg-ls-turquoise border-ls-turquoise;
}

.tag--active.tag--root {
  @apply bg-ls-green border-ls-green;
}
</style>
