<template>
  <Modal
    class="add-screen-tag-modal"
    @close="onClose"
    :heading="$t('components.addScreenTag.heading')"
  >
    <template #modal-content>
      <div
        class="add-screen-tag-modal__form-field add-screen-tag-modal__old-tags"
        v-if="tags.length > 0"
      >
        <label class="add-screen-tag-modal__label">
          {{ $t("components.addScreenTag.oldLabel") }}
        </label>
        <div class="add-screen-tag-modal__tags">
          <Tag
            :tag="tag"
            class="add-screen-tag-modal__tag"
            v-for="(tag, index) in tags"
            :key="index"
            @click.native="tag.chosen = !tag.chosen"
          />
        </div>
      </div>

      <div class="add-screen-tag-modal__form-field">
        <label class="add-screen-tag-modal__label">
          {{ $t("components.addScreenTag.newLabel") }}
        </label>
        <input class="add-screen-tag-modal__input" v-model="tag" />

        <Button
          class="add-screen-tag-modal__add-btn"
          :disabled="isDisabled"
          theme="secondary"
          v-if="isDisabled"
        >
          {{ $t("components.addScreenTag.addBtn") }}
        </Button>

        <Button class="add-screen-tag-modal__add-btn" @click="onAddTag" v-else>
          {{ $t("components.addScreenTag.addBtn") }}
        </Button>
      </div>

      <Button class="add-screen-tag-modal__btn" @click="onConfirm">
        {{ $t("components.addScreenTag.confirmBtn") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Tag from "./Tag.vue";
import Button from "./Button.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Modal,
    Tag,
    Button
  },
  props: {
    screenId: {
      type: String,
      requiered: true
    }
  },
  data: () => ({
    tag: "",
    tags: [],
    newTags: []
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),
    isDisabled() {
      return (
        this.tag === "" ||
        this.tags.some(el => el.name === this.tag.toLowerCase().trim()) ||
        this.newTags.some(el => el.name === this.tag.toLowerCase().trim())
      );
    },
    screens() {
      return this.team.screens;
    },
    currentTags() {
      let screen = this.screens.find(screen => screen.id === this.screenId);
      return screen.tags ? screen.tags : [];
    },
    pTags() {
      if (this.parentTeam && this.parentTeam.tags) {
        let keys = Object.keys(this.parentTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.parentTeam.tags[key].isParentTag = true;
          });

          return this.parentTeam.tags;
        }
      }

      return {};
    },
    rTags() {
      if (this.rootTeam && this.rootTeam.tags) {
        let keys = Object.keys(this.rootTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.rootTeam.tags[key].isRootTag = true;
          });

          return this.rootTeam.tags;
        }
      }

      return {};
    }
  },
  mounted() {
    let tags = [];
    if (this.team.tags) {
      tags = Object.entries(this.team.tags).map(([id, value]) => ({
        id,
        name: value.name
      }));
    }

    if (this.team.parentTeam) {
      let parentTags = Object.entries(this.pTags).map(([id, value]) => ({
        id,
        name: value.name,
        isParentTag: value.isParentTag
      }));

      parentTags.forEach(tag => {
        tags.push(tag);
      });
    }

    if (this.team.rootTeam && this.team.rootTeam !== this.team.parentTeam) {
      let rootTags = Object.entries(this.rTags).map(([id, value]) => ({
        id,
        name: value.name,
        isRootTag: value.isRootTag
      }));

      rootTags.forEach(tag => {
        tags.push(tag);
      });
    }

    let tempTags = tags.map(tag => {
      if (this.currentTags.some(elem => elem.id === tag.id)) {
        return {
          name: tag.name,
          id: tag.id,
          chosen: true,
          isParentTag: tag.isParentTag ? true : false,
          isRootTag: tag.isRootTag ? true : false
        };
      } else {
        return {
          name: tag.name,
          id: tag.id,
          chosen: false,
          isParentTag: tag.isParentTag ? true : false,
          isRootTag: tag.isRootTag ? true : false
        };
      }
    });
    this.tags = tempTags;
  },
  methods: {
    ...mapActions("screens", ["updateScreens"]),
    onClose() {
      this.$emit("close");
    },
    onAddTag() {
      let date = new Date();
      let id = String(date.getTime());
      let tagName = this.tag.toLowerCase().trim();
      if (tagName.length > 0) {
        this.tags.push({
          id,
          name: tagName,
          chosen: true,
          isParentTag: false,
          isRootTag: false
        });
        this.newTags.push({ id, name: tagName });
        this.tag = "";
      }
    },
    onConfirm() {
      let chosenTags = this.tags.filter(tag => {
        return tag.chosen;
      });

      let tags = chosenTags.map(tag => {
        return {
          id: tag.id,
          isParentTag: tag.isParentTag,
          isRootTag: tag.isRootTag
        };
      });

      if (this.newTags.length > 0) {
        let teamTags =
          this.team.tags && Object.keys(this.team.tags).length !== 0
            ? this.team.tags
            : {};

        this.newTags.forEach(tag => {
          teamTags[tag.id] = {
            name: tag.name
          };
        });
        this.team.tags = teamTags;
      }

      this.screens.map(screen => {
        if (screen.id === this.screenId) {
          screen.tags = tags;
        }
      });
      this.updateScreens();
      this.onClose();
    }
  }
};
</script>

<style lang="scss">
.add-screen-tag-modal {
  .modal__content {
    max-width: 600px;
  }

  &__old-tags {
    @apply mb-4 mt-6;
  }

  &__form-field {
    position: relative;
  }

  &__add-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &__btn {
    @apply mt-6 w-full;
  }

  &__label {
    @apply text-xs font-semibold;
  }

  &__input {
    @apply bg-ls-gray-100 border border-ls-gray-300 rounded-lg px-2 py-3 w-full;
    font-size: 14px;
    line-height: 20px;
  }

  &__tag,
  &__new-tag {
    @apply mt-2 mr-2;
  }
}
</style>
